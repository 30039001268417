/* eslint-disable no-extra-boolean-cast */
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-body">
          <div>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="items"
              loading-text="Malumot Yuklanmoqda"
              class="elevation-1"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.remain_amount_overall`]="{ item }">
                {{ item.remain_amount_overall | mask }}
              </template>
              <template v-slot:[`item.remain_amount_overall_change`]="{ item }">
                <input
                  type="text"
                  class="m-0 h-100"
                  @blur="amount(item)"
                  v-currency="options"
                  v-model="item.remain_amount_overall_change"
                />
              </template>
              <template v-slot:[`item.period_usage1`]="{ item }">
                <input
                  type="text"
                  class="m-0 h-100"
                  v-model="item.period_usage1"
                />
              </template>
              <template v-slot:[`item.profit`]="{ item }">
                <input
                  disabled
                  type="text"
                  class="m-0 h-100"
                  :value="item.profit | mask"
                />
              </template>
              <template v-slot:[`item.zarar`]="{ item }">
                <input
                  type="text"
                  disabled
                  class="m-0 h-100"
                  :value="item.zarar | mask"
                />
              </template>
            </v-data-table>
          </div>
          <!-- <div class="row m-4"> -->
          <div class="text-right my-4">
            <v-btn @click="save" color="primary">Saqlash</v-btn>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      totalProfit: 0,
      lastTotalProfit: 0,
      totalLose: 0,
      profit: '',
      items: [],
      lose: '',
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Inventar',
          value: 'inventar_number'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'AV qold',
          value: 'remain_amount_overall'
        },
        {
          text: 'Foydalanish muddati',
          value: 'period_usage'
        },
        {
          text: 'Yangi qoldiq',
          value: 'remain_amount_overall_change'
        },
        {
          text: ' Yangi foydalanish mudd',
          value: 'period_usage1'
        },
        {
          text: 'Foyda',
          value: 'profit'
        },
        {
          text: 'Zarar',
          value: 'zarar'
        },
        {
          text: '',
          value: 'action'
        }
      ],
      value: null,
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false,
      placement_expenditure: null
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  watch: {
    getMainAssets: function (val) {
      val.forEach((element) => {
        const data = {}
        for (let i in element) {
          data[i] = element[i]
        }
        data.remain_amount_overall_change = element.remain_amount_overall
        data.period_usage1 = element.period_usage
        this.lastTotalProfit += element.remain_amount_overall
        data.profit = 0
        data.zarar = 0
        this.items.push(data)
      })
      const data = {
        id: 1,
        remain_amount_overall_change: 0,
        remain_amount_overall: this.lastTotalProfit,
        profit: 0,
        zarar: 0
      }
      this.items.push(data)
    },
    items: {
      handler: function (val) {
        this.totalProfit = 0
        this.totalLose = 0
        // for (let i = 1; i < val.length; i++) {
        //   this.totalProfit += this.toInt(val[i].profit);
        //   this.totalLose += this.toInt(val[i].zarar);
        //   val[0].zarar += this.toInt(val[i].profit);
        //   val[0].zarar += this.toInt(val[i].zarar);
        // }
        val.forEach((x) => {
          this.totalProfit += this.toInt(x.profit)
          this.totalLose += this.toInt(x.zarar)
        })
        this.totalProfit.toLocaleString('es-US')
      },
      deep: true
    }
  },
  created() {
    var d = new Date()
    var n = d.getFullYear()
    this.$store.dispatch('tillDateList', n - 1 + '-12-31')
  },
  computed: {
    getMainAssets() {
      const data = this.$store.state.requests.tillDateList
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  methods: {
    save() {
      var d = new Date()
      var n = d.getFullYear()
      const data = {
        anual_report: {
          name: n - 1 + '-12-31 yil uchun xisobot.',
          oper_year: n - 1 + '-12-31'
        },
        main_assets: []
      }
      this.items.splice(-1, 1)
      this.items.forEach((x) => {
        const data1 = {
          main_assets_id: x.id,
          new_remain_value: this.toInt(x.remain_amount_overall_change),
          new_period_usage: this.toInt(x.period_usage1),
          oper_date: n + '-12-31',
          comment: x.comment,
          new_report_start_date: n + '-01-01',
          new_report_start_date_remain: this.toInt(
            x.remain_amount_overall_change
          )
        }
        data.main_assets.push(data1)
      })
      this.$store.dispatch('createDateList', data).then((res) => {
        if (res.status == 200) {
          setTimeout(() => {
            window.close()
          }, 1000)
        }
      })
    },
    amount(item) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(item.remain_amount_overall_change)) {
        item.profit = 0
      }
      if (item.remain_amount_overall_change !== 0) {
        if (
          this.toInt(item.remain_amount_overall) -
            this.toInt(item.remain_amount_overall_change) >
          0
        ) {
          item.profit = (
            this.toInt(item.remain_amount_overall) -
            this.toInt(item.remain_amount_overall_change)
          ).toLocaleString('es-US')
          item.zarar = 0
        } else if (
          this.toInt(item.remain_amount_overall) -
            this.toInt(item.remain_amount_overall_change) <
          0
        ) {
          item.profit = 0
          item.zarar = (
            this.toInt(item.remain_amount_overall) -
            this.toInt(item.remain_amount_overall_change)
          ).toLocaleString('es-US')
        } else {
          item.profit = 0
          item.zarar = 0
        }
        this.caclTotal()
      }
    },
    caclTotal() {
      this.items[this.items.length - 1].profit = 0
      this.items[this.items.length - 1].zarar = 0
      for (let i = 0; i < this.items.length - 1; i++) {
        this.items[this.items.length - 1].zarar += this.toInt(
          this.items[i].zarar
        )
        this.items[this.items.length - 1].profit += this.toInt(
          this.items[i].profit
        )
      }
    },
    toInt(val) {
      if (typeof val == 'string') {
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else return val
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: ' Asosiy vositalar' }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 1rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 1rem !important; */
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  /* min-height: 300px; */
  width: 100%;
}
</style>
